/**
 * Andrey Zybin
 * andgopro@gmail.com
 */

import { NgModule } from '@angular/core';
import { SlsTrackingService } from './services/sls-tracking.service';
import { SlsTrackingStateManagementModule } from './state-management/sls-tracking-state-management.module';

@NgModule({
  imports: [
    SlsTrackingStateManagementModule,
  ],
  providers: [
    SlsTrackingService,
  ]
})
export class SlsTrackingModule {

}
