/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AbstractActions} from '../../../../core/state-management/abstract-actions';
import {
  GtmAgentAuthPayload,
  GtmAgentTimeControlPayload,
  GtmPageInitPayload,
  GtmSendMessagePayload,
} from './gtm.payload';
import {Gtm} from './gtm.state';
import {GtmPayload} from './gtm.payload';

@Injectable()
export class GtmActions extends AbstractActions<GtmPayload> {
  GtmPageInitAction = this.action<GtmPageInitPayload>(`${this.getEntity()} Page init view`);
  GtmAgentAuthAction = this.action<GtmAgentAuthPayload>(`${this.getEntity()} User auth action`);
  GtmAgentTimeControlAction = this.action<GtmAgentTimeControlPayload>(`${this.getEntity()} Set agent time control`);
  GtmSendMessageAction = this.action<GtmSendMessagePayload>(`${this.getEntity()} Gtm send message action`);


  getEntity(): string {
    return '[GTM]';
  }

  constructor(
    store: Store<Gtm>
  ) {
    super(store);
  }
}
