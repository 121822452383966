/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {concatMap, map} from 'rxjs/operators';
import {AbstractAction, AbstractActions} from './abstract-actions';
import {AbstractPayload} from './abstract-payload';

export abstract class AbstractEffects<P extends AbstractPayload> {
  startLoading$ = createEffect(() => this.actions.pipe(
    ofType<any>(this.abstractActions.StartLoadingAction.TYPE),
    map(action => this.parseAction(action)),
    concatMap(({completeActions}) => completeActions)
  ));

  endLoading$ = createEffect(() => this.actions.pipe(
    ofType<any>(this.abstractActions.EndLoadingAction.TYPE),
    map(action => this.parseAction(action)),
    concatMap(({completeActions}) => completeActions)
  ));

  addMany$ = createEffect(() => this.actions.pipe(
    ofType<any>(this.abstractActions.AddManyAction.TYPE),
    map(action => this.parseAction(action)),
    concatMap(({completeActions}) => completeActions)
  ));

  upsertMany$ = createEffect(() => this.actions.pipe(
    ofType<any>(this.abstractActions.UpsertManyAction.TYPE),
    map(action => this.parseAction(action)),
    concatMap(({completeActions}) => completeActions)
  ));

  replaceAll = createEffect(() => this.actions.pipe(
    ofType<any>(this.abstractActions.ReplaceAllAction.TYPE),
    map(action => this.parseAction(action)),
    concatMap(({completeActions}) => completeActions)
  ));

  removeAll = createEffect(() => this.actions.pipe(
    ofType<any>(this.abstractActions.RemoveAllAction.TYPE),
    map(action => this.parseAction(action)),
    concatMap(({completeActions}) => completeActions)
  ));

  protected constructor(
    protected actions: Actions,
    protected abstractActions: AbstractActions<any>
  ) {

  }

  protected ofType<T extends P>(type: string) {
    const pipe = this.actions.pipe(
      ofType<any>(type),
      map(a => this.parseAction<T>(a)),
    );

    pipe.subscribe((...rest) => {

    });

    return pipe;
  }

  protected parseAction<T>(action: AbstractAction<T>) {
    const {type, payload, completeActions} = action;

    return {
      type, payload, completeActions
    };
  }
}
