/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {ToolBarTickerComponent} from './tool-bar-ticker/tool-bar-ticker.component';
import {ToolBarTimerComponent} from './tool-bar-timer/tool-bar-timer.component';

@NgModule({
  declarations: [
    ToolBarTimerComponent,
    ToolBarTickerComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
  ],
  exports: [
    ToolBarTimerComponent,
    ToolBarTickerComponent,
  ]
})
export default class ToolBarTimerModule {

}
