import {Injectable} from '@angular/core';
import {Actions, createEffect} from '@ngrx/effects';
import {concatMap} from 'rxjs/operators';
import {AbstractEffects} from '../../../../core/state-management/abstract-effects';
import {GtmActions} from './gtm.actions';
import {
  GtmPayload,
  GtmPageInitPayload,
  GtmAgentAuthPayload,
  GtmSendMessagePayload, GtmAgentTimeControlPayload
} from './gtm.payload';
import {GtmService} from '../../services/gtm.service';

@Injectable()
export class GtmEffects extends AbstractEffects<GtmPayload> {
  initPage$ = createEffect(() => this.ofType<GtmPageInitPayload>(this.gtmActions.GtmPageInitAction.TYPE).pipe(
    concatMap(({payload, completeActions}) => {
      this.gtmService.registerPageViewEvent();
      return [
        ...completeActions,
      ];
    })
  ));

  agentAuth$ = createEffect(() => this.ofType<GtmAgentAuthPayload>(this.gtmActions.GtmAgentAuthAction.TYPE).pipe(
    concatMap(({payload, completeActions}) => {
      this.gtmService.registerEntityTypeAuth(payload.entityType, payload.agent);
      return [
        ...completeActions,
      ];
    })
  ));

  setAgentTimeControl$ = createEffect(() => this.ofType<GtmAgentTimeControlPayload>(this.gtmActions.GtmAgentTimeControlAction.TYPE).pipe(
    concatMap(({payload, completeActions}) => {
      this.gtmService.registerEntityTypeAgentTimeControl(payload.entityType, payload.agent, payload.params);
      return [
        ...completeActions,
      ];
    })
  ));

  setUserMessage$ = createEffect(() => this.ofType<GtmSendMessagePayload>(this.gtmActions.GtmSendMessageAction.TYPE).pipe(
    concatMap(({payload, completeActions}) => {
      this.gtmService.registerEntityTypeByMessage(payload.userMessage, payload.agent, payload.sender, payload.recipient);

      return [
        ...completeActions,
      ];
    })
  ));

  constructor(
    protected actions: Actions,
    protected gtmActions: GtmActions,
    protected gtmService: GtmService,
  ) {
    super(actions, gtmActions);
  }
}
