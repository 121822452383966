/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Actions, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {first} from 'rxjs/operators';
import {SyncActions} from '../../features/base/state-management/sync/sync.actions';
import {UserActions} from '../../features/base/state-management/user/user.actions';
import {AbstractService} from './abstract.service';
import {ConfigService} from './config.service';
import {GtmActions} from '../../features/base/state-management/gtm/gtm.actions';

@Injectable({
  providedIn: 'root'
})
export class AppInitService extends AbstractService {
  constructor(
    http: HttpClient,
    protected service: TranslateService,
    protected configService: ConfigService,
    protected userActions: UserActions,
    protected syncActions: SyncActions,
    protected actions: Actions,
    protected gtmActions: GtmActions,
  ) {
    super(http);
  }

  get uri(): string {
    return 'init';
  }

  init() {
    return this.get().toPromise().then((data: any) => {
      const {
        user,
        timezone,
        timestamp,
        pauseLimit,
        selectiveScopesList,
        lang: {use, translations},
        lastSync: lastId,
        reactions: reactions
      } = data;

      this.configService.set('timezone', timezone);
      this.configService.timestamp = timestamp;
      this.configService.pauseLimit = pauseLimit;
      this.configService.setReactions = reactions;

      if (selectiveScopesList) {
        this.configService.selectiveScopes = selectiveScopesList;
      }

      this.service.setTranslation(use, translations);
      this.service.use(use);

      setTimeout(() => new this.userActions.SetUserAction(user, [
        // TODO Send data to Google Tag Manager
        new this.gtmActions.GtmPageInitAction(),
        // new this.syncActions.SetLastId({lastId}),
      ]).dispatch()); // TODO find better solution

      return this.actions.pipe(
        ofType(this.userActions.AfterUserSetAction.TYPE),
        first()
      ).toPromise();
    }).catch((...rest) => {
      console.error('App Init');
      console.dir(rest);
    });
  }
}
