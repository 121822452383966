/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

export const environment = {
  slsTrackingOn: false,
  slsTrackingApi: 'https://new-dev-api.globalchatsystem.com',
  api: 'https://dev-api.globalchatsystem.com',
  production: true
};
