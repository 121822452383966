/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

export const routeNames = {
  auth: 'auth',
  login: 'login',
  logout: 'logout',
  agent: 'agent',
  home: 'home',
  dashboard: 'dashboard',
  dashboardStart: 'start',
  conversation: 'conversation',
  stock: 'stock',
  inbox: 'inbox',
  follow: 'follow',
  past: 'past',
  archive: 'archive',
  note: 'note',
  meetUp: 'meet-up',
  stat: 'stat',
  customer: 'customer',
};
