/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {NgModule} from '@angular/core';
import {StateManagementModule} from './state-management/state-management.module';

@NgModule({
  declarations: [

  ],
  imports: [
    StateManagementModule
  ],
  exports: [

  ]
})
export class CoreModule {

}
