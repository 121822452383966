/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AbstractActions} from '../../../../core/state-management/abstract-actions';
import {SetLastIdPayload, SyncPayload} from './sync.payload';
import {Sync} from './sync.state';

@Injectable()
export class SyncActions extends AbstractActions<SyncPayload> {
  SetLastId = this.action<SetLastIdPayload>(`${this.getEntity()} Set Last Id`);

  getEntity(): string {
    return '[SYNC]';
  }

  constructor(
    store: Store<Sync>
  ) {
    super(store);
  }
}
