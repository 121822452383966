/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {Injectable} from '@angular/core';
import {AbstractAction} from '../../../core/state-management/abstract-actions';
import {AbstractReducer} from '../../../core/state-management/abstract-reducer';
import {SlsTrackingActions} from './sls-tracking.actions';
import {SlsTrackingAuthenticatePayload} from './sls-tracking.payload';
import {SlsTracking, SlsTrackingState} from './sls-tracking.state';

@Injectable()
export class SlsTrackingReducer extends AbstractReducer<SlsTracking, SlsTrackingState, SlsTrackingAuthenticatePayload> {
  get storeName(): string {
    return 'slsTracking';
  }

  get reducers(): {[p: string]: (action: AbstractAction<SlsTrackingAuthenticatePayload>, state: SlsTrackingState) => SlsTrackingState} {
    return {

    };
  }

  constructor(
    protected actions: SlsTrackingActions
  ) {
    super(actions);
  }
}
