/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, finalize, first, map, tap, withLatestFrom} from 'rxjs/operators';
import {LayoutType} from '../../../../core/constants/app.config';
import {ConfigService} from '../../../../core/services/config.service';
import {AbstractEffects} from '../../../../core/state-management/abstract-effects';
import {NoteActions} from '../../../home/features/note/state-management/note.actions';
import {AgentStatService} from '../../services/agent-stat.service';
import {UserService} from '../../services/user.service';
import {SlsTrackingService} from '../../../../tools/sls-tracking/services/sls-tracking.service';
import {UserActions} from './user.actions';
import {GtmActions} from '../gtm/gtm.actions';
import {LoadUserDataPayload, SetAgentShiftPayload, UserPayload} from './user.payload';
import {UserReducer} from './user.reducer';

@Injectable()
export class UserEffects extends AbstractEffects<UserPayload> {
  loginUser$ = createEffect(() => this.actions.pipe(
    ofType<any>(this.userActions.LoginUserAction.TYPE),
    map(action => this.parseAction<UserPayload>(action)),
    concatMap(({payload, completeActions}) => {
      return [
        new this.userActions.SetUserAction(payload),
        ...completeActions
      ];
    })
  ));

  logoutUser$ = createEffect(() => this.actions.pipe(
    ofType<any>(this.userActions.LogoutUserAction.TYPE),
    map(action => this.parseAction<UserPayload>(action)),
    concatMap(({completeActions}) => {
      return [
        new this.userActions.SetUserAction(null),
        ...completeActions
      ];
    })
  ));

  userSet$ = createEffect(() => this.ofType<UserPayload>(this.userActions.SetUserAction.TYPE).pipe(
    concatMap(({payload, completeActions}) => {
      const {token = null, isSuper = false} = (payload || {}) as any;

      localStorage.setItem('console_auth', token);

      if (!isSuper) {
        this.configService.set('layout', LayoutType.BLANK);
      }

      return [
        new this.userActions.AfterUserSetAction(payload),
        ...completeActions
      ];
    })
  ));

  afterUserSet$ = createEffect(() => this.ofType<UserPayload>(this.userActions.AfterUserSetAction.TYPE).pipe(
    concatMap(({payload, completeActions}) => {
      if (payload && payload['id']) {
        this.slsTrackingService.startUpdateToken();
      }

      return [
        ...completeActions
      ];
    })
  ));

  loadUserData$ = createEffect(() => this.actions.pipe(
    ofType<any>(this.userActions.LoadUserDataAction.TYPE),
    map(action => this.parseAction<LoadUserDataPayload>(action)),
    concatMap(({payload, completeActions}) => {
      return this.userService.loadData(payload.id).pipe(
        concatMap(data => {
          return [
            new this.userActions.UpsertOneAction(data),
            ...completeActions
          ];
        }),
        finalize(() => new this.userActions.UserDataLoadedAction().dispatch())
      );
    })
  ));

  setAgentShift$ = createEffect(() => this.ofType<SetAgentShiftPayload>(this.userActions.SetAgentShiftAction.TYPE).pipe(
    tap(() => new this.userActions.StartLoadingAction().dispatch()),
    withLatestFrom(this.userReducer.selectAgent()),
    concatMap(([{payload, completeActions}, user]) => {
      // TODO Send data to Google Tag Manager
      return this.slsTrackingService.sendEvent(payload.entityType).pipe(
        concatMap(data => {
          return this.agentService.saveAgentStat(payload).pipe(
            concatMap((response) => {
              return [
                new this.gtmActions.GtmAgentTimeControlAction({
                  agent: user,
                  entityType: payload.entityType,
                  params: response.gtm,
                }),
                ...completeActions
              ];
            }),
            finalize(() => new this.userActions.EndLoadingAction().dispatch())
          );
        })
      );
    }),
  ));

  constructor(
    protected actions: Actions,
    protected userActions: UserActions,
    protected userService: UserService,
    protected userReducer: UserReducer,
    protected agentService: AgentStatService,
    protected configService: ConfigService,
    protected gtmActions: GtmActions,
    protected slsTrackingService: SlsTrackingService,
  ) {
    super(actions, userActions);
  }
}
