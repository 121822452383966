import {Injectable} from '@angular/core';
import {AbstractAction} from '../../../../core/state-management/abstract-actions';
import {AbstractReducer} from '../../../../core/state-management/abstract-reducer';
import {GtmActions} from './gtm.actions';
import {GtmPayload} from './gtm.payload';
import {Gtm, GtmState} from './gtm.state';

@Injectable()
export class GtmReducer extends AbstractReducer<Gtm, GtmState, GtmPayload> {
  constructor(
    protected actions: GtmActions
  ) {
    super(actions);
  }

  get storeName(): string {
    return 'gtm';
  }

  get reducers(): {[p: string]: (action: AbstractAction<GtmPayload>, state: GtmState) => GtmState} {
    return {

    };
  }
}
