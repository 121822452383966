/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {Injectable} from '@angular/core';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Observable} from 'rxjs';
import {AbstractAction} from '../../../../core/state-management/abstract-actions';
import {AbstractReducer} from '../../../../core/state-management/abstract-reducer';
import {SseActions} from './sse.actions';
import {RegisterListenerPayload, SsePayload, UnRegisterListenerPayload} from './sse.payload';
import {SseChannel, SseState} from './sse.state';
import {Select} from '../../../../core/decorators/select.decorator';

@Injectable()
export class SseReducer extends AbstractReducer<SseChannel, SseState, SsePayload> {
  @Select()
  selectIsEnabled: () => Observable<boolean>;

  get storeName(): string {
    return 'sse';
  }

  get reducers(): { [p: string]: (action: AbstractAction<SsePayload>, state: SseState) => SseState } {
    return {
      [this.actions.RegisterListener.TYPE]: (action: AbstractAction<RegisterListenerPayload>, state) => {
        return this.adapter.upsertOne(action.payload as any, state);
      },
      [this.actions.UnRegisterListener.TYPE]: (action: AbstractAction<UnRegisterListenerPayload>, state) => {
        return this.adapter.removeOne(action.payload.eventName, state);
      },
      [this.actions.StartPing.TYPE]: (action, state: SseState): SseState => {
        return {...state, isEnabled: true};
      },
      [this.actions.StopPing.TYPE]: (action, state: SseState): SseState => {
        return {...state, isEnabled: false};
      },
    };
  }

  protected adapter: EntityAdapter<SseChannel> = createEntityAdapter({
    selectId: (channel => channel.eventName),
    sortComparer: false,
  });

  getInitialState(): SseState {
    return this.adapter.getInitialState({
      isEnabled: true,
    });
  }

  constructor(
    protected actions: SseActions
  ) {
    super(actions);
  }

  selectByEventName(name: string): Observable<SseChannel> {
    return this.select((state: EntityState<SseChannel>) => state.entities[name] ?? null) as any;
  }
}
