/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';

import {AgentStatService} from 'src/app/features/base/services/agent-stat.service';
import {UserActions} from '../../../../../../features/base/state-management/user/user.actions';
import {UserReducer} from '../../../../../../features/base/state-management/user/user.reducer';
import {Agent} from '../../../../../../features/base/state-management/user/user.state';
import {ToolBarTickerComponent} from '../tool-bar-ticker/tool-bar-ticker.component';
import {SnackBarActions} from '../../../../../../features/base/state-management/snack-bar/snack-bar.actions';

@Component({
  selector: 'app-tool-bar-timer',
  templateUrl: './tool-bar-timer.component.html',
  styleUrls: ['./tool-bar-timer.component.scss'],
})
export class ToolBarTimerComponent implements OnInit, AfterViewInit {
  timerStatus = 'stopped';
  isLoading = this.userReducer.selectIsLoading();
  isTimersSet = false;

  @Input()
  agent: Agent;

  @Output()
  onStart = new EventEmitter();

  @Output()
  onPause = new EventEmitter();

  @Output()
  onResume = new EventEmitter();

  @Output()
  onEnd = new EventEmitter();

  @ViewChild('mainTicker') mainTicker: ToolBarTickerComponent;
  @ViewChild('breakTicker') breakTicker: ToolBarTickerComponent;

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected agentStatService: AgentStatService,
    protected userActions: UserActions,
    protected userReducer: UserReducer,
    protected snackBarActions: SnackBarActions,
  ) {

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const agentLatestStat = this.agent.agentLatestStat;
      // start timer
      if (agentLatestStat?.entityType === 'shift_start') {
        this.timerStatus = 'started';
        this.mainTicker.setSeconds(agentLatestStat?.mainSeconds);
        this.mainTicker.startTimer();
        this.breakTicker.stopTimer();
      }
      else if (agentLatestStat?.entityType === 'shift_pause') {
        this.timerStatus = 'paused';
        this.mainTicker.setSeconds(agentLatestStat?.mainSeconds);
        this.breakTicker.setSeconds(agentLatestStat?.pauseSeconds);
        this.agentStatService.setSecondsBetweenPause(agentLatestStat?.pauseSeconds);
        this.mainTicker.pauseTimer();
        this.breakTicker.startTimer();
        this.agentStatService.startTimerBetweenPause();
      }
      else if (agentLatestStat?.entityType === 'shift_resume') {
        this.mainTicker.setSeconds(agentLatestStat?.mainSeconds);
        this.timerStatus = 'started';
        this.mainTicker.startTimer();
        this.breakTicker.stopTimer();

        if (Number(agentLatestStat?.lastPauseSeconds) != 0) {
          this.agentStatService.setSecondsBetweenPause(agentLatestStat?.lastPauseSeconds);
          this.agentStatService.startTimerBetweenPause();
        }
      }

      this.isTimersSet = true;
      this.changeDetectorRef.markForCheck();
    });
  }

  startMainTimer() {
    this.recordAgentShiftLog({
      entityType: 'shift_start',
    });

    this.timerStatus = 'started';
    this.mainTicker.startTimer();
    this.breakTicker.stopTimer();
    this.changeDetectorRef.markForCheck();
  }

  endMainTimer() {
    if (confirm('Do you really wanna to end timer?')) {
      this.recordAgentShiftLog({
        entityType: 'shift_end',
      });
      this.timerStatus = 'stopped';
      this.mainTicker.stopTimer();
      this.breakTicker.stopTimer();
      this.changeDetectorRef.markForCheck();
    }
  }

  pauseBreakTimer() {
    if (!this.isTimersSet) {
      return;
    }

    if (!this.agentStatService.checkTimerBetweenPause()) {
      new this.snackBarActions.ShowSuccessAction({
        message: 'You can only pause once per hour! Left: ' + this.agentStatService.getHMS()
      }).dispatch();

      return;
    }

    this.recordAgentShiftLog({
      entityType: 'shift_pause',
    });

    this.timerStatus = 'paused';
    this.mainTicker.pauseTimer();
    this.breakTicker.startTimer();
    this.agentStatService.startTimerBetweenPause();
    this.changeDetectorRef.markForCheck();
  }

  resumeBreakTimer() {
    this.recordAgentShiftLog({
      entityType: 'shift_resume',
    });

    this.timerStatus = 'started';
    this.mainTicker.startTimer();
    this.breakTicker.stopTimer();
    this.changeDetectorRef.markForCheck();
  }

  recordAgentShiftLog(payload) {
    new this.userActions.SetAgentShiftAction(payload).dispatch();
  }
}
