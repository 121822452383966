/**
 * Andrey Zybin
 * andgopro@gmail.com
 */

import {NgModule} from '@angular/core';
import {SlsTrackingModule} from './sls-tracking/sls-tracking.module';

@NgModule({
  declarations: [

  ],
  imports: [
    SlsTrackingModule,
  ],
  exports: [
    SlsTrackingModule,
  ],
})
export class ToolsModule {

}
