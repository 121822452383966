import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {SlsTrackingActions} from './sls-tracking.actions';
import {SlsTrackingEffects} from './sls-tracking.effects';
import {SlsTrackingReducer} from './sls-tracking.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([
      SlsTrackingEffects,
    ])
  ],
  providers: [
    SlsTrackingReducer,
    SlsTrackingActions,
  ]
})
export class SlsTrackingStateManagementModule {
  constructor(
    slsTrackingReducer: SlsTrackingReducer
  ) {
    slsTrackingReducer.register();
  }
}
