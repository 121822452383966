/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AbstractActions} from '../../../../core/state-management/abstract-actions';
import {PagingLengthPayload, PagingInitPayload, PagingPageIndexPayload, PagingPayload} from './paging.payload';
import {Paging} from './paging.state';

@Injectable()
export class PagingActions extends AbstractActions<PagingPayload> {
  InitAction = this.action<PagingInitPayload>(`${this.getEntity()} Init`);

  SetLengthAction = this.action<PagingLengthPayload>('[PAGING] Set length');

  SetPageIndex = this.action<PagingPageIndexPayload>('[PAGING] Set page index');

  getEntity(): string {
    return '[PAGING]';
  }

  constructor(
    store: Store<Paging>
  ) {
    super(store);
  }
}
