import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {filter, first, map} from 'rxjs/operators';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {Agent, User} from '../state-management/user/user.state';
import {AbstractState} from '../../../core/state-management/abstract-state';

export const gtmEntityTypes = {
  login: 'login',
  logout: 'logout',
  claim: 'claim',
  claim_interval: 'claim_interval',
  claim_send_interval: 'claim_send_interval',
  fail: 'fail',
  send_message_text: 'send_message_text',
  send_message_image: 'send_message_image',
  send_message_length: 'send_message_length',
  receive_message_image: 'receive_message_image',
  receive_message_text: 'receive_message_text',
  send_message_interval: 'send_message_interval',
  shift_start: 'shift_start',
  shift_pause: 'shift_pause',
  shift_resume: 'shift_resume',
  shift_end: 'shift_end',
  waiting_time: 'waiting_time',
  claim_follow: 'claim_follow',
  claim_follow_send_interval: 'claim_follow_send_interval',
};

export interface GtmMessage extends AbstractState {
  conversationId: number;
  text: string;
  senderId: number;
  recipientId: number;
  attachment: null | {url: string; name: string};
  isSystem?: boolean;
  gtm?: GtmAdditionalParams;
}
export interface GtmAdditionalParams {
  interval: number;
  log_type: string;
}

export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) { return target; }
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) { Object.assign(target, { [key]: {} }); }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

@Injectable({
  providedIn: 'root'
})
export class GtmService {

  protected defaultPushTagItem = {
    agent: {
      id: 0,
      agentId: 0,
      displayName: '',
      isSuper: false,
      permission: 0,
    },
    log: {
      entityType: '',
      timestamp: 0,
      dateTime: '',
      interval: 0,
      messageId: 0,
      messageLength: 0,
      isGift: false
    },
    sender: {
      id: 0,
      displayName: '',
      type: '',
      scope: '',
      premium: false,
      affId: 0,
      isDuplicate: false
    },
    recipient: {
      id: 0,
      displayName: '',
      type: '',
      scope: '',
      premium: false,
      affId: 0,
      isDuplicate: false
    },
  };

  protected eventTagPageView = 'agent_page_view';
  protected eventTagAgentStat = 'agent_stat';

  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService
  ) { }

  getCurrentTime(): object {
    const dateTime = new Date();

    return {
      timestamp: Math.floor(dateTime.getTime() / 1000),
      dateTime: dateTime.toISOString(),
    };
  }

  isJsonString( str: string ): boolean {
    try {
      return typeof JSON.parse(str) === 'object';
    } catch (e) {
      return false;
    }
  }

  isImageMessage( message: GtmMessage ): boolean {
    return message.attachment !== undefined && message.attachment !== null;
  }

  isGiftMessage( message: GtmMessage ): boolean {
    if ( message.isSystem !== undefined && message.isSystem && this.isJsonString(message.text) ) {
      const dJson = JSON.parse(message.text);
      if ( dJson.entityType !== undefined && dJson.entityType === 'virtual_gift' ) {
        return true;
      }
    }

    return false;
  }

  getDataAgent( agent: Agent ) {
    return  {
      id: agent.id,
      agentId: agent.agentId,
      displayName: agent.displayName,
      isSuper: agent.isSuper,
      permission: agent.permission,
    };
  }

  getDataUser( user: User ) {
    return {
      id: user.id,
      displayName: user.displayName,
      type: user.type,
      scope: user.scope,
      premium: user.premium,
      affId: user.affId,
      isDuplicate: user.isDuplicate,
    };
  }

  // page_view
  registerPageViewEvent() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.sendPageViewEvent();
    });
  }

  // send_message_text | send_message_image | send_message_length
  registerEntityTypeByMessage(
    message: GtmMessage,
    agent: Agent,
    sender: User,
    recipient: User,
  ) {
    const isImage = this.isImageMessage(message);
    const isGift = this.isGiftMessage(message);
    let entityType = gtmEntityTypes.send_message_text;
    let log = {
      messageId: message.id,
    };

    if ( isImage || isGift ) {
      entityType = gtmEntityTypes.send_message_image;

      if ( isGift ) {
        Object.assign(log, {isGift: true});
      }
    }

    // create send_message_text | send_message_image
    this.sendEntityTypeEventByUsers(
      entityType, agent, sender, recipient, log
    );

    // create send_message_length
    if ( entityType === gtmEntityTypes.send_message_text ) {
      Object.assign(log, {messageLength: message.text.length});
      this.sendEntityTypeEventByUsers(
        gtmEntityTypes.send_message_length, agent, sender, recipient, log
      );
    }

    // create send_message_interval | claim_follow_send_interval | claim_send_interval
    if ( message.gtm !== undefined ) {
      Object.assign(log, {interval: message.gtm.interval});

      switch ( message.gtm.log_type ) {
        case gtmEntityTypes.send_message_interval:
        case gtmEntityTypes.claim_follow_send_interval:
        case gtmEntityTypes.claim_send_interval:
          this.sendEntityTypeEventByUsers(
            message.gtm.log_type, agent, sender, recipient, log
          );
          break;
      }
    }
  }

  // shift_start | shift_pause -> waiting_time | shift_resume | shift_resume
  registerEntityTypeAgentTimeControl( gtmEntityType: string, agent: Agent, params?: GtmAdditionalParams ) {
    let log = {};

    switch (gtmEntityType) {
      case gtmEntityTypes.shift_resume:
      case gtmEntityTypes.shift_end:
        if ( params.interval !== undefined ) {
          log = {
            interval: params.interval
          };
        }
        break;
    }

    this.sendEntityTypeEventByAgent( gtmEntityType, agent, log );

    switch (gtmEntityType) {
      case gtmEntityTypes.shift_pause:
        this.sendEntityTypeEventByAgent( gtmEntityTypes.waiting_time, agent );
        break;
    }
  }

  // login | logout
  registerEntityTypeAuth( gtmEntityType: string, agent: Agent ) {
    switch (gtmEntityType) {
      case gtmEntityTypes.logout:
        this.sendEntityTypeEventByAgent( gtmEntityTypes.shift_end, agent );
        break;
    }

    this.sendEntityTypeEventByAgent( gtmEntityType, agent );
  }

  sendPageViewEvent() {
    const currentTime = this.getCurrentTime();
    const url = this.router.url;
    const gtmData = {
      event: this.eventTagPageView,
      page_location: window.location.href,
      page_path: url,
    };

    this.gtmService.pushTag({...gtmData, ...currentTime});
  }

  sendEntityTypeEventByAgent( gtmEntityType: string, agent: Agent, dataLog?: object ) {
    const currentTime = this.getCurrentTime();
    const gtmData = {
      event: this.eventTagAgentStat,
      agent: this.getDataAgent(agent),
    };
    const log = {
      entityType: gtmEntityType,
    };

    let gtmLog;
    if ( dataLog != null ) {
      gtmLog = {log: {...log, ...dataLog, ...currentTime}};
    } else {
      gtmLog = {log: {...log, ...currentTime}};
    }

    const item = mergeDeep({}, this.defaultPushTagItem, gtmData, gtmLog);
    this.gtmService.pushTag(item);
  }

  sendEntityTypeEventByUsers(
    gtmEntityType: string,
    agent: Agent,
    sender: User,
    recipient: User,
    dataLog?: object
  ) {
    const currentTime = this.getCurrentTime();
    const gtmData = {
      event: this.eventTagAgentStat,
      agent: this.getDataAgent(agent),
      sender: this.getDataUser(sender),
      recipient: this.getDataUser(recipient),
    };
    const log = {
      entityType: gtmEntityType,
    };

    let gtmLog;
    if ( dataLog != null ) {
      gtmLog = {log: {...log, ...dataLog, ...currentTime}};
    } else {
      gtmLog = {log: {...log, ...currentTime}};
    }

    const item = mergeDeep({}, this.defaultPushTagItem, gtmData, gtmLog);
    this.gtmService.pushTag(item);
  }
}
