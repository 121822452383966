import jwt_decode from 'jwt-decode';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {AbstractService} from '../../../core/services/abstract.service';
import {environment} from "../../../../environments/environment";
import {SlsTrackingAuthModel, SlsTrackingEventType} from '../models/sls-tracking-auth-model';
import {SlsTrackingAuthPayload} from '../state-management/sls-tracking.payload';

@Injectable()
export class SlsTrackingService extends AbstractService {
  static UPDATE_TIME = 1800; // sec
  static UPDATE_INTERVAL = 1800; // sec

  private updateTimer;
  private statuses = [];

  protected api = `${environment.api}/hoi/console`;

  constructor(
    http: HttpClient
  ) {
    super(http);
    this.statuses['shift_start'] = 'online';
    this.statuses['shift_end'] = 'offline';
    this.statuses['shift_pause'] = 'pause';
    this.statuses['shift_resume'] = 'unpause';

  }

  get url(): string {
    return environment.slsTrackingApi;
  }

  get isSlsTrackingOn(): boolean {
    return environment.slsTrackingOn;
  }

  getUrl(uri: string): string {
    return `${this.url}/${uri}`;
  }

  get uri() {
    return '';
  };

  auth(authPayload: SlsTrackingAuthModel): Observable<{data: any}> {
    if (!this.isSlsTrackingOn) {
      this.clear();
      return of({data: {mock: true}})
    }

    return this.post('authenticate', authPayload) as any;
  }

  refreshAuth() {
    return this.http.patch(this.getUrl('authenticate'), null, {
      headers: {
        'Authorization': `Bearer ${this.getToken()}`,
      },
    });
  }

  private refreshAuthWrapper() {
    const nowTimestamp = Math.floor(Date.now() / 1000);
    const prevExp = Math.floor(this.getTokenExpire());
    const exp = prevExp - nowTimestamp;

    if (exp <= SlsTrackingService.UPDATE_TIME + 60) {
      try {
        this.refreshAuth().subscribe((response) => {
          this.updateToken({token: response['access_token']});
        })
      } catch (e) {
        console.log(e);
      }
    }
  }

  sendEvent(event: string) {
    if (!this.isSlsTrackingOn || !this.getToken().length) {
      return of({data: {mock: true}})
    }

    const statusType: SlsTrackingEventType = {type: this.statuses[event]};
    const body = {
      queue: 'gcs',
      message: {
        event: 'agent-status-update',
        status: statusType.type,
      }
    }

    return this.http.post(this.getUrl('event'), body, {
      headers: {
        'Authorization': `Bearer ${this.getToken()}`,
        'Content-Type' : 'application/json'
      },
    });
  }

  updateToken(authPayload: SlsTrackingAuthPayload): void {
    if (!this.isSlsTrackingOn) {
      return;
    }

    localStorage.setItem('sls_auth', authPayload.token);

    // update token expire
    const jwtDecode = jwt_decode(authPayload.token)['exp'] ?? 0;
    this.updateTokenExpire(jwtDecode);
  }

  private updateTokenExpire(exp: number): void {
    localStorage.setItem('sls_auth_exp', exp.toString());
  }

  getTokenExpire(): number {
    return Number(localStorage.getItem('sls_auth_exp')) ?? 0;
  }

  getToken(): string {
    const token = localStorage.getItem('sls_auth');

    if (!token || token == 'undefined' || token == undefined) {
      return '';
    }

    return localStorage.getItem('sls_auth');
  }

  startUpdateToken() {
    if (!this.isSlsTrackingOn || !this.getToken().length) {
      return;
    }

    // first call
    this.refreshAuthWrapper();

    // set timer
    this.updateTimer = setInterval(() => {
      this.refreshAuthWrapper();
    }, SlsTrackingService.UPDATE_INTERVAL * 1000);
  }

  logout(): void {
    if (!this.isSlsTrackingOn || !this.getToken().length) {
      return;
    }

    this.sendEvent('shift_end').subscribe();
    clearInterval(this.updateTimer);
  }

  clear() {
    localStorage.setItem('sls_auth', '');
    localStorage.setItem('sls_auth_exp', '');
  }
}
