/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {AppConfig} from '../constants/app.config';
import {APP_CONFIG} from '../config/config.module';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private timeStamp: number;
  private pauseLimitValue: number;
  private interval: Subscription;
  private configSubject = new BehaviorSubject(this.config);
  private selectiveScopesList?: Object = null;
  private selectedScopeValue: string = 'default';
  private reactions: Array<number>;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig
  ) {

  }

  get(key: keyof AppConfig) {
    return this.config[key] ?? null;
  }

  set(key: keyof AppConfig, value: any) {
    const config = Object.assign({}, this.config) as any;

    config[key] = value;

    this.configSubject.next(config);
  }

  get timezone(): string {
    return this.get('timezone') as any;
  }

  get timestamp() {
    return this.timeStamp;
  }

  set timestamp(timestamp: number) {
    this.timeStamp = timestamp;

    this.interval && this.interval.unsubscribe();
    this.interval = interval(1000).subscribe(() => {
      this.timeStamp++;
    });
  }

  set pauseLimit(seconds: number) {
    this.pauseLimitValue = seconds;
  }

  get pauseLimit() {
    return this.pauseLimitValue ?? 3600;
  }

  set selectiveScopes(list: Object) {
    this.selectiveScopesList = list;
  }

  get selectiveScopes(): Object {
    return this.selectiveScopesList;
  }

  set selectedScope(scope: string) {
    this.selectedScopeValue = scope;
  }

  get selectedScope(): string | null {
    return this.selectedScopeValue;
  }

  getAsObservable() {
    return this.configSubject.asObservable();
  }

  get getReactions(): Array<number>{
    return this.reactions;
  }

  set setReactions(reactions: Array<number>){
    this.reactions = reactions;
  }
}
