<perfect-scrollbar>
  <div>
    <img src="./assets/images/logo.png" style="width: 120px; padding: 10px">
  </div>
  <mat-nav-list>
    <mat-list-item [routerLink]="'/'" routerLinkActive="active">
      <mat-icon matListIcon color="accent">folder</mat-icon>
      <h3 matLine>{{'console_menu_home' | translate}}</h3>
    </mat-list-item>
  </mat-nav-list>
</perfect-scrollbar>
