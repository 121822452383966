/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {Injectable} from '@angular/core';
import {Actions, createEffect} from '@ngrx/effects';
import {from} from 'rxjs';
import {concatMap, distinctUntilChanged, last, tap} from 'rxjs/operators';
import {AbstractEffects} from '../../../../core/state-management/abstract-effects';
import {SseActions} from '../sse/sse.actions';
import {SyncActions} from './sync.actions';
import {SetLastIdPayload, SyncPayload} from './sync.payload';

@Injectable()
export class SyncEffect extends AbstractEffects<SyncPayload> {


  // setUser$ = createEffect(() => this.ofType<SetLastIdPayload>(this.syncActions.SetLastId.TYPE).pipe(
  //   distinctUntilChanged((prev, curr) => prev.payload.lastId === curr.payload.lastId),
  //   concatMap(({payload: {lastId}}) => {
  //     if (lastId) {
  //       return [
  //         new this.syncActions.RemoveAllAction(),
  //         new this.sseActions.UnRegisterListener({eventName: 'sseSync'}),
  //         new this.sseActions.RegisterListener({
  //           eventName: 'sseSync',
  //           params: {lastId},
  //           listener: (sync: Array<any>) => {
  //             !!sync.length && from(sync).pipe(
  //               tap(s => new this.syncActions.AddOneAction(s).dispatch()),
  //               last(),
  //             ).subscribe(lastSync => new this.syncActions.SetLastId({lastId: lastSync.id}).dispatch());
  //           }
  //         })
  //       ];
  //     }
  //
  //     return [
  //       new this.sseActions.UnRegisterListener({eventName: 'sseSync'}),
  //     ];
  //   })
  // ));

  constructor(
    protected actions: Actions,
    protected syncActions: SyncActions,
    protected sseActions: SseActions,
  ) {
    super(actions, syncActions);
  }
}
