/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {ChangeDetectorRef, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-tool-bar-ticker',
  templateUrl: './tool-bar-ticker.component.html',
  styleUrls: ['./tool-bar-ticker.component.scss']
})
export class ToolBarTickerComponent implements OnInit {
  timer = 0;
  timerString = '';
  interval;
  constructor(
    protected changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.timer++;
      this.convertHMS();
      this.changeDetectorRef.markForCheck();
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.interval);
    this.timer = 0;
    this.timerString = '';
    this.changeDetectorRef.markForCheck();
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  getSeconds() {
    return this.timer;
  }
  setSeconds(seconds) {
    this.timer = seconds;
    this.changeDetectorRef.markForCheck();

    if (this.timer > 0) {
      this.convertHMS();
    }
  }

  convertHMS() {
    const hours   = Math.floor(this.timer / 3600);
    const minutes = Math.floor((this.timer - (hours * 3600)) / 60);
    const seconds = this.timer - (hours * 3600) - (minutes * 60);
    // tslint:disable-next-line:one-variable-per-declaration
    let hourString = hours.toString(), minuteString = minutes.toString(), secondString = seconds.toString();
    if (hours < 10) {
      hourString = '0' + hours;
    }
    if (minutes < 10) {
        minuteString = '0' + minutes;
    }
    if (seconds < 10) {
      secondString = '0' + seconds;
    }
    this.timerString = hourString + ':' + minuteString + ':' + secondString;
    this.changeDetectorRef.markForCheck();
  }
}
