/**
 * Kairat Bakytov
 * kainisoft@gmail.com
 */

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {appConfig} from './core/constants/app.config';
import {ConfigModule} from './core/config/config.module';
import {CoreModule} from './core/core.module';
import {InterceptorService} from './core/services/interceptor.service';
import {AppInitService} from './core/services/app-init.service';
import {LayoutModule} from './layout/layout.module';
import {ToolsModule} from './tools/tools.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot(),

    MatSnackBarModule, // TODO refactor

    CoreModule,
    ConfigModule.forRoot(appConfig),
    AppRoutingModule,
    LayoutModule,
    ToolsModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-TLDNC9Q'
    }),
  ],
  exports: [GoogleTagManagerModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppInitService],
      useFactory: (appInitService: AppInitService) => () => appInitService.init()
    }
  ],
})
export class AppModule { }
