import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AbstractActions} from '../../../core/state-management/abstract-actions';
import {SlsTrackingAuthenticatePayload, SlsTrackingAuthPayload} from './sls-tracking.payload';
import {SlsTracking} from './sls-tracking.state';

@Injectable()
export class SlsTrackingActions extends AbstractActions<SlsTrackingAuthenticatePayload> {
  UpdateTokenAction = this.action<SlsTrackingAuthPayload>(`${this.getEntity()} Update token`);
  LogoutAction = this.action(`${this.getEntity()} Logout`);

  getEntity(): string {
    return '[SLS Tracking]';
  }

  constructor(
    store: Store<SlsTracking>
  ) {
    super(store);
  }
}
