/**
 * Sydykov Chyngyz
 * chyngyz6@gmail.com
 */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractService} from '../../../core/services/abstract.service';
import {ConfigService} from '../../../core/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class AgentStatService extends AbstractService {
  protected intervalBetweenPause;
  protected timerBetweenPause = 0;
  protected isTimerStart = false;

  constructor(
    http: HttpClient,
    protected configService: ConfigService,
  ) {
    super(http);
  }

  get uri(): string {
    return 'agent-stat';
  }
  saveAgentStat(payload): Observable<any> {
    return this.post(`${payload.entityType}`, payload);
  }

  startTimerBetweenPause() {
    if (!this.checkTimerBetweenPause || this.isTimerStart) {
      return;
    }

    this.isTimerStart = true;
    this.intervalBetweenPause = setInterval(() => {
      if (this.timerBetweenPause >= this.configService.pauseLimit) {
        this.stopTimerBetweenPause();

        return;
      }

      this.timerBetweenPause++;
    }, 1000);
  }

  stopTimerBetweenPause() {
    clearInterval(this.intervalBetweenPause);
    this.timerBetweenPause = 0;
    this.isTimerStart = false;
  }

  checkTimerBetweenPause() {
    return this.timerBetweenPause === 0 || this.timerBetweenPause >= this.configService.pauseLimit;
  }

  setSecondsBetweenPause(seconds) {
    if (this.timerBetweenPause !== 0) {
      return;
    }

    this.timerBetweenPause = seconds;
  }

  getHMS() {
    const time = this.configService.pauseLimit - this.timerBetweenPause;
    const hours   = Math.floor(time / 3600);
    const minutes = Math.floor((time - (hours * 3600)) / 60);
    const seconds = time - (hours * 3600) - (minutes * 60);
    // tslint:disable-next-line:one-variable-per-declaration
    let hourString = hours.toString(), minuteString = minutes.toString(), secondString = seconds.toString();
    if (hours < 10) {
      hourString = '0' + hours;
    }
    if (minutes < 10) {
      minuteString = '0' + minutes;
    }
    if (seconds < 10) {
      secondString = '0' + seconds;
    }
    return hourString + ':' + minuteString + ':' + secondString;
  }
}
